import React, { useEffect, Suspense, lazy } from 'react'
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom'

// === Components === //
import { Spin } from 'antd'

// === Hooks === //
import useUserAddress from './hooks/useUserAddress'

// === Utils === //
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'

// === Styles === //
import 'antd/dist/reset.css'
import './App.css'

// === Hooks === //
import useUserProvider from './hooks/useUserProvider'

// === Routers === //
const Dashboard = lazy(() => import('./pages/Dashboard/index'))
const AdminV2 = lazy(() => import('./pages/Admin/AdminV2'))
const AdminV3 = lazy(() => import('./pages/Admin/AdminV3'))
const AllocationV2 = lazy(() => import('./pages/Allocation/AllocationV2'))
const AllocationV3 = lazy(() => import('./pages/Allocation/AllocationV3'))
const Ethi = lazy(() => import('./pages/ethi/index'))
const VaultBufferV2 = lazy(() => import('./pages/VaultBuffer/VaultBufferV2'))
const VaultBufferV3 = lazy(() => import('./pages/VaultBuffer/VaultBufferV3'))
const USDi = lazy(() => import('./pages/USDi/index'))
const USDiAdapter = lazy(() => import('./pages/Adapter/USDiAdapter'))
const ETHiAdapter = lazy(() => import('./pages/Adapter/ETHiAdapter'))
const DailyReport = lazy(() => import('./pages/DailyReport/index'))
const DailyReportDetail = lazy(() => import('./pages/DailyReport/detail'))

function App() {
  const { userProvider, loading: isLoadingChainId, loadWeb3Modal, logoutOfWeb3Modal } = useUserProvider()
  const selectedChainId = userProvider && userProvider._network && userProvider._network.chainId

  const changeNetwork = async targetNetwork => {
    if (isEmpty(targetNetwork)) return
    // 如果metamask已经使用的是targetNetwork的话，则修改localStorage.REACT_APP_NETWORK_TYPE之后，进行页面刷新。
    if (targetNetwork.chainId === selectedChainId) {
      localStorage.REACT_APP_NETWORK_TYPE = targetNetwork.chainId
      setTimeout(() => {
        window.location.reload()
      }, 1)
      return
    }
    const ethereum = window.ethereum
    const data = [
      {
        chainId: '0x' + targetNetwork.chainId.toString(16),
        chainName: targetNetwork.name,
        nativeCurrency: targetNetwork.nativeCurrency,
        rpcUrls: [targetNetwork.rpcUrl],
        blockExplorerUrls: [targetNetwork.blockExplorer]
      }
    ]
    console.log('data', data)

    let switchTx
    // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
    try {
      switchTx = await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: data[0].chainId }]
      })
    } catch (switchError) {
      // not checking specific error code, because maybe we're not using MetaMask
      try {
        switchTx = await ethereum.request({
          method: 'wallet_addEthereumChain',
          params: data
        })
      } catch (addError) {
        console.log('addError=', addError)
        // handle "add" error
      }
    }

    if (switchTx) {
      console.log(switchTx)
    }
  }

  useEffect(() => {
    if (isUndefined(selectedChainId)) return
    if ((isEmpty(localStorage.REACT_APP_NETWORK_TYPE) && selectedChainId > 0) || `${selectedChainId}` !== localStorage.REACT_APP_NETWORK_TYPE) {
      localStorage.REACT_APP_NETWORK_TYPE = selectedChainId
      setTimeout(() => {
        window.location.reload()
      }, 100)
    }
  }, [selectedChainId])

  const address = useUserAddress(userProvider)

  const nextProps = {
    address: isEmpty(userProvider) ? '' : address,
    loadWeb3Modal,
    logoutOfWeb3Modal,
    userProvider,
    localChainId: selectedChainId,
    changeNetwork,
    isLoadingChainId
  }

  const routerLoadingJsx = (
    <Spin tip="Loading...">
      <div className="content" />
    </Spin>
  )

  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route exact path="/">
            <Suspense fallback={routerLoadingJsx}>
              <Dashboard {...nextProps} />
            </Suspense>
          </Route>
          <Route path="/adminv2">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <AdminV2 {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/adminv3">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <AdminV3 {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/allocationv2">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <AllocationV2 {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/allocationv3">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <AllocationV3 {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/ethi">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <Ethi {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/vault-buffer-v2">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <VaultBufferV2 {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/vault-buffer-v3">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <VaultBufferV3 {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/usdi">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <USDi {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/usdi-adapter">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <USDiAdapter {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route path="/ethi-adapter">
            {isEmpty(userProvider) ? (
              <Redirect to={{ pathname: '/' }} />
            ) : (
              <Suspense fallback={routerLoadingJsx}>
                <ETHiAdapter {...nextProps} />
              </Suspense>
            )}
          </Route>
          <Route exact path="/daily-reports">
            <Suspense fallback={routerLoadingJsx}>
              <DailyReport {...nextProps} />
            </Suspense>
          </Route>
          <Route path="/daily-reports/:id">
            <Suspense fallback={routerLoadingJsx}>
              <DailyReportDetail {...nextProps} />
            </Suspense>
          </Route>
          <Route path="*">
            <Redirect
              to={{
                pathname: '/'
              }}
            />
          </Route>
        </Switch>
      </HashRouter>
    </div>
  )
}

export default App
